/**
 * @file
 * Styles for browsers that don't support media queries
 */

@import "variables";

#navbar #block-search-form {
  width: 200px;
}

#block-block-1 {
  padding-top: 12px;
}

#block-menu-block-2 ul li a.cart {
  margin-top: -9px;
}

// Add border to product in list display
.node-product-display.view-mode-search_result:hover .field-type-commerce-product-reference, 
.node-flag-display.view-mode-search_result:hover .field-type-commerce-product-reference, 
.view-mode-search_result.node-flag-display:hover .field-type-commerce-product-reference, 
.node-apparel-display.view-mode-search_result:hover .field-type-commerce-product-reference {
  border: 1px solid #ccc;
  border-top: none;
}

#block-menu-block-2 ul li.first {
  position: relative;
  top: -15px;
}

#block-commerce-cart-cart {
  top: 55px;
}